












import { defineComponent } from '@vue/composition-api';

const Page = defineComponent({
  name: 'Page',
});

export default Page;
